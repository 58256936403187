//===================Connection related start===================
export const HANDLE_CONNECTION_REQUEST = "HANDLE_CONNECTION_REQUEST";
export const HANDLE_CONNECTION_SUCCESS = "HANDLE_CONNECTION_SUCCESS";
export const HANDLE_CONNECTION_FAILURE = "HANDLE_CONNECTION_FAILURE";

export const RESET = "RESET";
//===================Connection related end===================

//===================PUBLIC INFO RELATED===================
export const LOAD_PUBLIC_CONTRACT = "LOAD_PUBLIC_CONTRACT";
export const SET_PUBLIC_INFO = "SET_PUBLIC_INFO";
//===================PUBLIC INFO RELATED===================

//===================PROVIDER ACTION RELATED===================
export const PROVIDER_ACTION_PENDING = "PROVIDER_ACTION_PENDING";
export const PROVIDER_ACTION_COMPLETE = "PROVIDER_ACTION_COMPLETE";
export const PROVIDER_ACTION_FAILURE = "PROVIDER_ACTION_FAILURE";

export const SET_PROVIDER_MODAL_OPEN = "SET_PROVIDER_MODAL_OPEN";
//===================END PROVIDER ACTION RELATED===================

//===================MISC===================
export const SET_MY_INFO = "SET_MY_INFO";
//===================END MISC===================