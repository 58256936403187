import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import FAQAcc from './FAQAcc';

const FAQComponents = () => {
    return (
        <div>
            <Box paddingY='4rem'>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item md='12' xs='12'>
                            <Box textAlign='center'>
                                <Typography
                                    variant='h4'
                                    gutterBottom
                                    component='h4'
                                    fontWeight='600'
                                    color='#627B7C'
                                >
                                    FAQ
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md='12' xs='12'>
                            <Box marginY='2rem'>
                                 <FAQAcc />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default FAQComponents;