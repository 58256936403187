import React, { useState } from 'react'
import { AppBar, Toolbar, Tabs, Tab, Box, Button, useMediaQuery, useTheme, Typography, Container } from '@mui/material'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

import musiclogo from './urbansystems_thick_white.png'
// import musiclogo from './logo.png'
import DrawerComponent from './DrawerComponent';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import ConnectWallet from './ConnectWallet';

const Pages = [
    {
        titles: 'NFT Minting',
        route: '#mintnfts'
    },
    {
        titles: 'MobiGoal Network Privilege',
        route: '#mobiclub'
    },
    {
        titles: 'Roadmap',
        route: '#roadmap'
    },
    {
        titles: 'FAQ',
        route: '#faq'
    },
    {
        titles: 'Contact Us',
        route: '#registr'
    },
]



const Headers = () => {
    const [value, setValue] = useState();
    const theme = useTheme();
    console.log(theme);

    const isMatch = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <React.Fragment>
            <AppBar position="sticky" sx={{ backgroundColor: '#627B7C', boxShadow: 'none' }}>
                <Container maxWidth='xl'>
                    <Toolbar>
                        <Box paddingY='8px' component={Link} to='/' onClick={() => {
                            const element = document.querySelector('#hero');
                            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                        }}>
                            <img src={musiclogo} atl='music nft logo' className='logowidths' />
                        </Box>
                        {
                            isMatch ? (
                                <>
                                    <DrawerComponent />
                                </>
                            ) : (
                                <>
                                    <Box marginLeft='2rem'>
                                        <Tabs textColor='inherit'
                                            value={value}
                                            onChange={(e, value) => setValue(value)}
                                            indicatorColor='#E76346' >
                                            {
                                                Pages.map((page) => (
                                                    <Tab
                                                        className='menusjia'
                                                        key={page}
                                                        label={page.titles}
                                                        onClick={() => {
                                                            const element = document.querySelector(page.route);
                                                            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                                                        }}
                                                        sx={{ fontWeight: 'normal' }} />
                                                ))
                                            }
                                        </Tabs>
                                    </Box>
                                    <Box sx={{ marginLeft: 'auto' }}>
                                        <ConnectWallet />
                                    </Box>
                                </>
                            )}
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    )
}

export default Headers;