import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { projectBaseURL } from '../../config/config';



const Input = styled('input')({
    // display: 'none',
    border: '1px solid #627B7C',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
    maxWidth: '100%',
});



const RegistrationComponents = () => {
    const [inputValues, setInputValues] = useState({
        name: '',
        company_name: '',
        business_nature: '',
        wallet_address: '',
        contact_number: '',
        email_address: '',
        message: '',
        consent: false,
    });

    const handleInputChange = e => {
        const { name, value, type, checked } = e.target;

        setInputValues(prevValues => {
            return {
                ...prevValues,
                [name]: type === "checkbox" ? checked : value,
                touched: {
                    ...prevValues.touched,
                    [name]: true,
                }
            }
        })
    }

    const register = () => {
        var formData = new FormData();

        if (!inputValues.consent) {
            toast.warn('Please consent');
            return;
        }

        for (const [key, value] of Object.entries(inputValues)) {
            if (value === '') {
                toast.warn('Plese fill out all values!');
                return;
            }
            formData.append(key, value);
        }

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                console.log(this.responseText);
                if(this.status !== 200) {
                    toast.error(this.responseText);
                    return;
                } 
                toast.success(this.responseText);
            }
        });

        xhr.open("POST", projectBaseURL + "api/send-mail.php");

        xhr.send(formData);
    }

    return (
        <div>
            <Box paddingY='4rem'>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item md='12' xs='12'>
                            <Box textAlign='center'>
                                <Typography
                                    variant='h4'
                                    gutterBottom
                                    component='h4'
                                    fontWeight='600'
                                    color='#627B7C'
                                >
                                    Registration
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                    <Box paddingY='3rem'>
                        <Box borderRadius='12px' position='relative' sx={{ border: '3px solid #627B7C', padding: '2rem' }}  >
                            <Grid container spacing={1}>
                                <Grid item md='5' xs='12'>
                                    <Box marginRight='1rem'>
                                        <Typography
                                            variant='h5'
                                            gutterBottom
                                            component='h5'
                                            fontWeight='500'
                                            color='#627B7C'>
                                            Get Yourself Registered for Latest Updates
                                        </Typography>
                                        <Typography variant='h6' paddingY='4px' fontWeight='400'
                                            gutterBottom component='div' textAlign='justify'
                                            letterSpacing='1.7px' lineHeight='1.8rem'
                                        >
                                            The applications will undergo a shortlisted process. Selected applicants will be informed and invited to the Event in Cyberport. For any enquiries, please contact mobigoalnft@urbansystems.com.hk.
                                            *By submitting this form to MobiPro (this Project), you agree with MobiPro (this Project) on sharing your information provided in this form with relevant event partners to assess your need and eligibility for this event. Information will be kept confidential and will only be shared with these relevant event partners
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md='7' xs='12'>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Box>
                                                    <TextField onChange={handleInputChange} value={inputValues.name} fullWidth name="name" label="Enter Your Name" />
                                                </Box>
                                            </Grid>
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Box>
                                                    <TextField onChange={handleInputChange} value={inputValues.company_name} fullWidth name="company_name" label="Company Name" />
                                                </Box>
                                            </Grid>
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Box>
                                                    <TextField onChange={handleInputChange} value={inputValues.business_nature} fullWidth name="business_nature" label="Business Nature" />
                                                </Box>
                                            </Grid>
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Box>
                                                    <TextField onChange={handleInputChange} value={inputValues.wallet_address} fullWidth name="wallet_address" label="Metamask Wallet Address" />
                                                </Box>
                                            </Grid>
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Box>
                                                    <TextField onChange={handleInputChange} value={inputValues.contact_number} fullWidth name="contact_number" label="Contact Number" />
                                                </Box>
                                            </Grid>
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Box>
                                                    <TextField onChange={handleInputChange} value={inputValues.email_address} fullWidth name="email_address" label="Email Address" />
                                                </Box>
                                            </Grid>
                                            <Grid item md='12' sm='12' xs='12'>
                                                <Box>
                                                    <TextareaAutosize onChange={handleInputChange} value={inputValues.message} name="message" minRows={5} style={{ width: '100%' }} placeholder="Type your message within 150 words..." />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box marginTop='2rem' display='flex' justifyContent='space-between'>
                                            <Box>
                                                <FormControlLabel onChange={handleInputChange} value={inputValues.consent} name="consent" control={<Checkbox defaultChecked />} />
                                            </Box>
                                            <Box>
                                                <Typography variant='body'
                                                    gutterBottom component='div'
                                                    textAlign='justify'
                                                >
                                                    By ticking the box here, I signify my consent for MobiPro(the Project) to use my personal data (primarily my name and contact details) in direct marketing services, products, facilities, activities and other subjects to me (primarily services, products, facilities, activities, events and subjects offered in relation to MobiPro(the Project) as more particularly set out in the Personal Information Collection Statement.
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className='positionbtnss' marginTop='1.3rem'>
                                            <Box display='flex' alignItems='center' justifyContent='flex-end' >
                                                <Button onClick={register} variant="contained" fontWeight='600' sx={{ background: '#627B7C !important', paddingX: '15px !important', display: 'flex !important', alignItems: 'center !important', justifyContent: 'space-between', width: '200px', fontSize: '18px', borderRadius: '12px' }} >
                                                    Register <span><ChevronRightIcon sx={{ background: '#779495 !important', height: '35px', width: '35px', borderRadius: '8px', position: 'relative', top: '4px' }} /></span>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default RegistrationComponents;