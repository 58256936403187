import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'

import mapsclubs from '../imgs/worldmap.png'
// import mapsclubs from '../imgs/maps.png'
import NetworkTeam from './NetworkTeam';


const MobiProClubMembership = () => {
    return (
        <div>
            <Box paddingTop='4rem'>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item md='12' xs='12'>
                            <Box>
                                <Box textAlign='center'>
                                    <Typography
                                        variant='h4'
                                        gutterBottom
                                        component='h4'
                                        fontWeight='600'
                                        color='#627B7C'
                                    >
                                        MobiGoal Network Privilege
                                    </Typography>
                                     
                                    <Typography
                                        variant='body1'
                                        gutterBottom
                                        component='body1'
                                        fontWeight='400'
                                        color='#000'
                                    >
                                        Learn and Pitch your Business Ideas to C-level,
                                        Venture Capitalists,<br /> Entrepreneurs and Leaders

                                    </Typography>

                                </Box>
                                {/*<Box paddingTop='1rem'>
                                    <img src={mapsclubs} width='100%' alt='map team' />
                                </Box>*/}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <NetworkTeam />
        </div>
    )
}

export default MobiProClubMembership;