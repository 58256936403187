import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import RemoveIcon from '@mui/icons-material/Remove';
import AddBoxIcon from '@mui/icons-material/AddBox';
import mintimg from '../imgs/001.jpg'
import mintimg2 from '../imgs/002.jpg'
import mintimg3 from '../imgs/003.jpg'
import { handleMint, publicWeb3 } from '../../redux/actions';
import { connect } from 'react-redux';

const Input = styled('input')({
    // display: 'none',
    border: '1px solid #627B7C',
    borderRadius: '8px',
    padding: '10px',
    width: '80px',
    textAlign: 'center',
});


const MintingNFTComponents = props => {
    const [count, setCount] = useState(1);


    return (
        <div id="mint-nfts">
            <Box paddingY='4rem' alignItems='center'>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item md='6' xs='12'>
                            <Box>
                                <Box>
                                    <Typography variant='h3' gutterBottom component='h3' color='#627B7C' fontWeight='600'>
                                        Mint MobiGoal NFTs
                                    </Typography>
                                    <Typography variant='h4' gutterBottom component='h3' color='#627B7C' fontWeight='600'>
                                        A Ride Moves Towards Your Goal
                                    </Typography>
                                    <Typography variant='body1' gutterBottom component='body1' color='#000' fontWeight='400'>
                                        MobiGoal is a bespoke NFT calling all people who have the inborn traits of being innovative and creative. MobiGoal owners can get a chance to take part in a Real McCoy experience by pitching their business ideas, in the latest autonomous vehicle MobiPro, to C-level, Venture Capitalists, Entrepreneurs and Leaders who are willing to invest and join activities to inspire your startup to move towards the goal – Go! Go! Goal!
                                    </Typography>
                                </Box>
                                <Box marginY='2rem'>
                                    <Box display='flex' alignItems='center'>
                                        <Box>
                                            <Typography variant='h6' component='h6' color='#627B7C' fontWeight='400'>
                                            Minting Cost 
                                            </Typography>
                                        </Box>
                                        <Box marginLeft='2rem'>
                                            <Typography variant='h6' component='h6' color='#000' fontWeight='400'>
                                                {/* {props.publicInfo.floorPrice ?
                                                    parseFloat(parseFloat(count * publicWeb3.utils.fromWei(props.publicInfo.floorPrice)).toFixed(6))
                                                : '-'} ETH */}
                                                0.1 ETH
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <Box>
                                            <Typography variant='h6' component='h6' color='#627B7C' fontWeight='400'>
                                                Sold
                                            </Typography>
                                        </Box>
                                        <Box marginLeft='2rem'>
                                            <Typography variant='h6' component='h6' color='#000' fontWeight='400'>
                                                {/* {props.publicInfo.totalSupply ? props.publicInfo.totalSupply : '-'}
                                                /{props.publicInfo.MAX_SUPPLY ? props.publicInfo.MAX_SUPPLY : '-'} */}
                                                0/120
                                                </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {/* <Typography variant='h6' component='h6' color='#000' fontWeight='400'>
                                            No of NFTs to mint
                                        </Typography> */}
                                    </Box>
                                    <Box paddingTop='1rem'>
                                        <Box display='flex' alignItems='center'>
                                            <Box position='relative'>
                                                <span className='removeicons'>
                                                    <RemoveIcon
                                                        onClick={count > 1 ? () => setCount(count - 1) : () => { }}
                                                        sx={{ color: count === 1 ? '#AAA' : '#627B7C' }}
                                                    />
                                                </span>
                                                <Input placeholder='1' value={count} />
                                                <span className='addicons'>
                                                    <AddBoxIcon
                                                        onClick={count < 20 ? () => setCount(count + 1) : () => { }}
                                                        sx={{ color: count === 20 ? '#AAA' : '#627B7C' }}
                                                    />
                                                </span>
                                            </Box>
                                            <Box marginLeft='1rem'>
                                                <Button
                                                disabled
                                                    variant="contained"
                                                    fontWeight='600'
                                                    sx={{
                                                        background: '#627B7C !important',
                                                        paddingX: '30px !important',
                                                        borderRadius: '8px',
                                                        color:'#fff !important'
                                                    }}
                                                    // disabled={props.providerActionPending}
                                                    // onClick={() => props.handleMint(count)}
                                                >
                                                   Coming Soon
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md='6' xs='12' margin='auto'>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item md='4' sm='4' xs='12'>
                                        <Box>
                                            <img src={mintimg} width='100%' style={{ borderRadius: '4px' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item md='4' sm='4' xs='12'>
                                        <Box>
                                            <img src={mintimg2} width='100%' style={{ borderRadius: '4px' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item md='4' sm='4' xs='12'>
                                        <Box>
                                            <img src={mintimg3} width='100%' style={{ borderRadius: '4px' }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid> 
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        publicInfo: state.publicInfo,
        providerActionPending: state.loaders.providerAction,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleMint: count => dispatch(handleMint(count))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MintingNFTComponents);