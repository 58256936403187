import mainContractABI from './ABI/mainContractABI.json';

const networkToUse = 'rinkeby';

export const mainContractInfo = {
    address: networkToUse === 'mainnet' ? '0x0'
        : '0xEbCac8b41fAEacc599025e1074d17Bc40488e9Cb',
    abi: mainContractABI,
    decimals: 5,
    name: 'Posteve NFT',
    symbol: 'POST',
}

export const projectBaseURL = "http://www.urbansystems.io/"

var network, networkName, infuraId = '4e990aac9bc9418b8112eb1ed524cf91', chainId, rpc,
    blockExplorerUrl, addEthereumChainParameter, addChainInstructionsURL = "https://metamask.zendesk.com/hc/en-us/articles/360058992772-Add-a-network-using-Chainlist-Extension-or-Mobile-";

switch (networkToUse) {
    case 'mainnet':
        network = 'https://bsc-dataseed.binance.org/';
        networkName = 'Binance Smart Chain';
        chainId = 56;
        rpc = {
            [chainId]: network,
        }
        blockExplorerUrl = 'https://bscscan.com/';
        addChainInstructionsURL = 'https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain/';
        addEthereumChainParameter = {
            chainId: '0x' + chainId.toString(16),
            chainName: networkName,
            nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
            },
            rpcUrls: [network],
            blockExplorerUrls: [blockExplorerUrl],
        }
        break;
    case 'testnet':
        network = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
        networkName = 'Smart Chain - Testnet';
        chainId = 97;
        rpc = {
            [chainId]: network,
        }
        blockExplorerUrl = 'https://testnet.bscscan.com/';
        addChainInstructionsURL = 'https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain/';
        addEthereumChainParameter = {
            chainId: '0x' + chainId.toString(16),
            chainName: networkName,
            nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
            },
            rpcUrls: [network],
            blockExplorerUrls: [blockExplorerUrl],
        }
        break;
    case 'rinkeby':
        network = 'https://rinkeby.infura.io/v3/';
        networkName = 'Rinkeby Test Network';
        chainId = 4;
        rpc = {
            [chainId]: network + infuraId,
        };
        blockExplorerUrl = 'https://rinkeby.etherscan.io';
        addEthereumChainParameter = {
            chainId: '0x' + chainId.toString(16),
            chainName: networkName,
            nativeCurrency: {
                name: 'Ether',
                symbol: 'ETH',
                decimals: 18,
            },
            rpcUrls: [network],
            blockExplorerUrls: [blockExplorerUrl],
        }
        break;
    default:
        break;
}

export { network, networkName, infuraId, chainId, rpc, blockExplorerUrl, addEthereumChainParameter, addChainInstructionsURL }