import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Footer from './Components/FooterCompo;lnents/Footer';
import Headers from './Components/HeaderTop/Headers';
import { SET_PROVIDER_MODAL_OPEN } from './redux/action-types';
import { handleConnection, handleDisconnection, loadPublicInfo } from './redux/actions';
import HomeComponents from './ViewComponents/HomeComponent/HomeComponents';
import ringsSvg from './images/files/rings.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = props => {
  useEffect(() => {
    props.loadPublicInfo();
    var connectedWith = sessionStorage.getItem('connectedWith');
    const { ethereum } = window;
    if (connectedWith) {
      props.handleConnection(connectedWith);
    }
    if (ethereum) {
      ethereum.on('accountsChanged', () => {
        connectedWith = sessionStorage.getItem('connectedWith');
        if (connectedWith) {
          props.handleConnection(connectedWith);
        }
      });
      ethereum.on('chainChanged', () => props.handleDisconnection());
    }
  }, []);

  useEffect(() => {
    if (!props.isConnecting) {
      props.closeProviderModal();
    }
  }, [props.isConnecting])

  return (
    <Router>
      <Headers />
      <ToastContainer />
      <Switch>
        <Route exact path='/' component={HomeComponents} />
      </Switch>
      <Footer />
      {props.providerActionPending && <Modal open={true}>
        <Box margin='auto' textAlign='center' style={{
          position: "absolute",
          left: '0',
          right: '0',
          top: '0',
          bottom: '0',
          margin: 'auto',
          zIndex: '11111',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          justifyContent: 'center',
          backdropFilter: ' blur(10px)',
        }}>
          <Typography sx={{ color: 'white' }} variant="h6">Transaction Pending</Typography>
          <img src={ringsSvg} height="100" width="100"></img>
          <Typography sx={{ color: 'white' }} variant="h6">Please do not refresh or navigate away from this page</Typography>
        </Box>
      </Modal>}
    </Router>
  )
}

const mapStateToProps = state => {
  return {
    providerModalOpen: state.providerModalOpen,
    isConnecting: state.isConnecting,
    providerActionPending: state.loaders.providerAction
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleConnection: providerChoice => dispatch(handleConnection(providerChoice)),
    handleDisconnection: () => dispatch(handleDisconnection()),
    closeProviderModal: () => dispatch({ type: SET_PROVIDER_MODAL_OPEN, payload: false }),
    loadPublicInfo: () => dispatch(loadPublicInfo()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);