import {
    mainContractInfo, chainId,
    addEthereumChainParameter,
    network,
    pairContractInfo,
    busdContractInfo,
    infuraId,
    rpc,
    networkName,
    addChainInstructionsURL,
} from '../config/config';
import store from './reducer';
import Web3 from 'web3';
import {
    HANDLE_CONNECTION_REQUEST,
    HANDLE_CONNECTION_SUCCESS,
    HANDLE_CONNECTION_FAILURE,
    PROVIDER_ACTION_FAILURE,
    RESET,
    LOAD_PUBLIC_CONTRACT,
    SET_PUBLIC_INFO,
    SET_MY_INFO,
    SET_PROVIDER_MODAL_OPEN,
    PROVIDER_ACTION_PENDING,
    PROVIDER_ACTION_COMPLETE
} from "./action-types";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


export const publicWeb3 = new Web3(network + infuraId);
export const publicContractWeb3 = new publicWeb3.eth.Contract(mainContractInfo.abi, mainContractInfo.address);

export const loadPublicInfo = () => {
    return async function (dispatch) {
        dispatch({ type: LOAD_PUBLIC_CONTRACT, payload: publicContractWeb3 });

        const {
            totalSupply,
            MAX_SUPPLY,
            floorPrice,
            maxMint
        } = publicContractWeb3.methods;

        await floorPrice().call().then(data => {
            dispatch({ type: SET_PUBLIC_INFO, payload: { floorPrice: data } })
        }).catch(e => {
            console.error('floorPrice: ', e);
        });

        totalSupply().call().then(data => {
            dispatch({ type: SET_PUBLIC_INFO, payload: { totalSupply: data } })
        }).catch(e => {
            console.error('totalSupply: ', e);
        });

        await MAX_SUPPLY().call().then(data => {
            dispatch({ type: SET_PUBLIC_INFO, payload: { MAX_SUPPLY: data } })
        }).catch(e => {
            console.error('MAX_SUPPLY: ', e);
        });

        maxMint().call().then(data => {
            dispatch({ type: SET_PUBLIC_INFO, payload: { maxMint: data } })
        }).catch(e => {
            console.error('maxMint: ', e);
        });
    }
}

export const handleMint = count => {
    return async function (dispatch) {
        const { web3, account, publicInfo } = store.getState();

        if (!account || !web3) {
            Swal.fire({
                title: 'Not connected!',
                text: 'Please connect with a wallet to perform this action!',
                icon: 'warning'
            }).then(status => {
                if (status.isConfirmed) {
                    dispatch({ type: SET_PROVIDER_MODAL_OPEN, payload: true });
                }
            })
            return;
        }

        var fp;

        if (!publicInfo.floorPrice) {
            fp = await publicContractWeb3.methods.floorPrice().call();
            return;
        } else {
            fp = publicInfo.floorPrice;
        }

        const contractWeb3 = new web3.eth.Contract(mainContractInfo.abi, mainContractInfo.address);

        const { mint } = contractWeb3.methods;
        dispatch({ type: PROVIDER_ACTION_PENDING });
        try {
            const gasLimit = await mint("0", count.toString()).estimateGas({ from: account, value: fp * count });
            await mint("0", count.toString()).send({ from: account, value: fp * count, gasLimit: gasLimit });
            dispatch({ type: PROVIDER_ACTION_COMPLETE });
            Swal.fire({
                icon: 'success',
                title: 'Cheers 🎉',
                text: `You have successfully minted! 
                    Your NFT${count > 1 ? 's' : ''} will be added to your wallet and should appear OpenSea shortly.`
            })
            dispatch(loadPublicInfo());
        } catch (e) {
            dispatch(handleProviderError(e));
        }
    }
}

//==============Connection related start===================
export const handleConnection = providerChoice => {
    return async function (dispatch) {
        dispatch({ type: HANDLE_CONNECTION_REQUEST });
        handleConnectionInternal(providerChoice).then(returnedState => {
            dispatch({ type: HANDLE_CONNECTION_SUCCESS, payload: returnedState });
        }).catch(error => {
            dispatch({ type: HANDLE_CONNECTION_FAILURE, payload: error });
        });
    }
}

const handleConnectionInternal = async (providerChoice) => {
    const { ethereum } = window;
    if (providerChoice !== 'WalletConnect' && ethereum && !ethereum.isTrust && parseInt(ethereum.chainId, 16) !== chainId) {
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x' + chainId.toString(16) }],
            });
        } catch (e) {
            if (e.code === 4001) {
                toast.info('Please add ' + addEthereumChainParameter.chainName + ' to use our app!');
            } else if (e.code === 4902) {
                try {
                    await ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [addEthereumChainParameter],
                    });
                } catch (e) {
                    if (e.code === 4001) {
                        toast.info('Please add ' + addEthereumChainParameter.chainName + ' to use our app!');
                    } else {
                        console.log('Error attempting to add chain: ', e);
                    }
                    return;
                }
            }
            else console.log(e);
            return;
        }
    }

    var localState = {
        provider: null,
    }

    switch (providerChoice) {
        case 'Metamask':
            if (ethereum && ethereum.isMetaMask) {
                var result = await handleAccountsRequest(ethereum);
                sessionStorage.setItem('connectedWith', 'Metamask');
                localState = {
                    ...localState,
                    ...result,
                }
            } else {
                toast.info("Couldn't find Metamask!");
            }
            break;
        case "WalletConnect":
            try {
                var walletConnectProvider = null;
                await import('@walletconnect/web3-provider').then(wc => {
                    const WalletConnectProvider = wc.default;
                    walletConnectProvider = new WalletConnectProvider({
                        infuraId: infuraId,
                        rpc: rpc,
                        chainId: chainId,
                        qrcode: true,
                        qrcodeModalOptions: {
                            mobileLinks: [
                                "metamask",
                                "trust",
                            ],
                        },
                    });
                })

                await walletConnectProvider.enable().then(async () => {
                    if (walletConnectProvider.chainId !== chainId) {
                        walletConnectProvider.disconnect();
                        Swal.fire({
                            icon: "warning",
                            title: 'Wrong network!',
                            html: `Please switch to ` + networkName + ` in your wallet app and try connecting again! <br><br>` +
                                ` You can refer to the instructions ` +
                                `<a target='_blank' rel='noreferrer' style="color: #7066E0" href='${addChainInstructionsURL}'>here</a> to add the network`,
                        });
                        return;
                    }

                    var result = await handleAccountsRequest(walletConnectProvider);
                    sessionStorage.setItem('connectedWith', 'WalletConnect');
                    localState = {
                        ...localState,
                        ...result,
                    }

                    walletConnectProvider.on("disconnect", () => {
                        sessionStorage.clear();
                        window.location.reload();
                    });

                    walletConnectProvider.on("accountsChanged", () => {
                        window.location.reload();
                    })

                    walletConnectProvider.on("chainChanged", () => {
                        walletConnectProvider.disconnect();
                    })
                });
            } catch (e) {
                if (walletConnectProvider.chainId !== chainId) {
                    if (walletConnectProvider.close) walletConnectProvider.close();
                    else if (walletConnectProvider.disconnect) walletConnectProvider.disconnect();
                    console.log('Issue connecting with WalletConnect: Chain IDs did not match!');
                    return;
                }

                console.log(e);
                return;
            }
            break;
        default:
            break;
    }
    return localState;
}

const handleAccountsRequest = async (provider) => {
    var localState = {};
    try {
        if (!provider.wc)
            await provider.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(provider);
        let accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const balance = await web3.eth.getBalance(account);
        const contractWeb3 = new web3.eth.Contract(mainContractInfo.abi, mainContractInfo.address);
        localState = { ...localState, web3: web3, contractWeb3: contractWeb3, account: account, balance: balance }
        // sessionStorage.setItem('account', account);
    } catch (error) {
        console.log('Error requesting accounts', error);
        store.dispatch({ type: RESET });
        throw new Error(error);
    }
    return localState;
}

export const handleDisconnection = () => {
    return function (dispatch) {
        dispatch({ type: RESET });
        return sessionStorage.clear();
    }
}

const handleProviderError = e => {
    return function (dispatch) {
        var errMess;
        try {
            errMess = JSON.parse(e.message.replace("Internal JSON-RPC error.", "")).message;
        } catch {
            try {
                errMess = e.message
            } catch {
                errMess = e
            }
        }
        if (errMess.includes('insufficient funds')) {
            errMess = 'Insufficient funds!';
        } else if (errMess.toLowerCase().includes('user denied')) {
            errMess = 'Transaction was cancelled';
        } else {
            errMess = errMess.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '');
        }
        console.error(e);
        dispatch({ type: PROVIDER_ACTION_FAILURE, payload: errMess });
        Swal.fire({ icon: 'error', title: 'Something went wrong', text: errMess });
    }
}
//===================Connection related end===================