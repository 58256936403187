import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MintingNFTComponents from '../MintingNFT/MintingNFTComponents';
import MobiProClubMembership from '../ClubMembership/MobiProClubMembership';
import RoadmapComponents from '../Roadmap/RoadmapComponents';
import FAQComponents from '../Faqs/FAQComponents';
import RegistrationComponents from '../Registration/RegistrationComponents';

import mobs from '../imgs/mobis.png'


const HomeComponents = () => {
  return (
    <div>
      <Box className='herobgimgs' id='hero'>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item md='6'>
              <Box classNmae='texthead'>
                <Box>
                  <Typography variant='h3' gutterBottom component='h3' fontWeight='600' color='#fff' sx={{display:'none'}}>
                    Go Pro , The Smart City
                  </Typography>
                   <img src={mobs} width='100%' alt='MOBI see me MOBI get you' />
                </Box>
                <Box paddingTop='2rem'>
                  <Button onClick={() => {
                    var element = document.getElementById("mint-nfts");
                    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
                  }} variant="contained" fontWeight='600' sx={{ background: '#627B7C !important', paddingX: '15px !important', display: 'flex !important', alignItems: 'center !important', justifyContent: 'space-between', width: '280px', fontSize: '18px', borderRadius: '12px' }} >
                    Mint MobiGoal NFTs <span><ChevronRightIcon sx={{ background: '#779495 !important', height: '35px', width: '35px', borderRadius: '8px', position: 'relative', top: '4px' }} /></span>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box id='mintnfts'>
        <MintingNFTComponents />
      </Box>
      <Box id='mobiclub'>
        <MobiProClubMembership />
      </Box>
      <Box id='roadmap'>
        <RoadmapComponents />
      </Box>
      <Box id='faq'>
        <FAQComponents />
      </Box>
      <Box id='registr'>
        <RegistrationComponents />
      </Box>
    </div>
  )
}

export default HomeComponents;