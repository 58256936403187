import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <Box bgcolor='#627B7C' paddingY='1em'>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item md='6' xs='12'>
                            <Box >
                                <Typography className='teramstext' color='#fff' variant='body2' fontWeight='400'>
                                    A Portfolio Company of <a href='http://www.urbansystems.io/' target='_blank' style={{ color: '#fff' }}>Ophylla</a> Ventures
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md='6' xs='12'>
                            <Box>
                                <Box className='teramstext2' flexWrap='wrap' display='flex' alignItems='center' justifyContent='flex-end'>
                                    <Box >
                                        <Typography color='#fff' variant='body2' fontWeight='400'>
                                            All Rights Reserved
                                        </Typography>
                                    </Box>
                                    <Box marginLeft='6px'>
                                        <Typography color='#fff' variant='body2' fontWeight='400'>
                                            |
                                        </Typography>
                                    </Box>
                                    <Box marginX='6px'>
                                        <Typography color='#fff' variant='body2' fontWeight='400'>
                                            Terms Of Use
                                        </Typography>
                                    </Box>
                                    <Box marginRight='6px'>
                                    <Typography color='#fff' variant='body2' fontWeight='400'>
                                        |
                                    </Typography>
                                </Box>
                                    <Box>
                                        <Typography color='#fff' variant='body2' fontWeight='400'>
                                            Privacy Policy
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default Footer;