import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

const accoritems = [
    {
        title: 'When can i mint a MobiPro NFT?.',
        disripition: 'The time for the public sale will be announced soon, Stay tuned',
    },
    {
        title: 'Where can i purchase a MobiPro NFT?',
        disripition:
            <Typography>You will be give a passcode to mint on pre sale if you are chose for giveaway, or mint directly from our mint website  <a href='http://urbansystems.io/' target='blank'>http://urbansystems.io/</a>
            </Typography>,
    },
    {
        title: 'How much will each MobiPro NFT cost?',
        disripition: 'Public sale 0.1 Etherem each.',
    },
    {
        title: 'What is the total supply?',
        disripition: <Typography>
            Total supply of MobiPro NFT is 300
            <br />
            Phase 1 120
            <br />
            Phase 2 180
        </Typography>,
    },
    {
        title: 'Which bloackchain and what wallet do i use to mint?',
        disripition: 'MobiPro is a Etherem block chain project that can be minted with both desktop and mobile version os MetaMask wallet.',
    },
    {
        title: 'What is an NFT? And what does it do?',
        disripition: 'A non-fungible token (NFT) is a non-interchangeable unit of data stored on a blockchain, a form of digital ledger, that can be sold and traded. Types of NFT data units may be associated with digital files such as photos, video and audio. Because each token is uniquely identifiable. Having an NFT. would include you in our MobiPro community. where the owners can own a chance of a one-on-one pitching section with those selected celebrities in Hong Kong. ',
    },
]



const FAQAcc = () => {
    return (
        <div>
            <Box>
                {accoritems.map((items) => (
                    <Accordion key={items} sx={{ background: '#BAC3C5', boxShadow: 'none', marginY: '5px', borderRadius: '12px !important', borderTop: '0px', padding: '8px' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h5' fontWeight='500'>
                                {items.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {items.disripition}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </div>
    );
}
export default FAQAcc;