import { Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import ConnectWallet from './ConnectWallet';

const Pages = [
  {
    titles: 'NFT Minting',
    route: '/#mintnfts'
  },
  {
    titles: 'Membership Privileges',
    route: '/#mobiclub'
  },
  {
    titles: 'Roadmap',
    route: '/#roadmap'
  },
  {
    titles: 'FAQ',
    route: '/#faq'
  },
  {
    titles: 'Contact Us',
    route: '/#registr'
  },
]


const DrawerComponent = () => {

  const [openDrawer, setOpenDrawer] = useState(false);


  return (
    <React.Fragment>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} >
        <Box sx={{ background: '#627B7C', height: '100%' }}>
          <List sx={{ marginTop: '3rem' }}>
            {
              Pages.map((page, index) => (
                <ListItemButton key={page} component={Link} to={page.route} onClick={() => setOpenDrawer(false)}>
                  <ListItemIcon>
                    <ListItemText sx={{ color: '#fff' }}>
                      {page.titles}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              ))
            }
            <Box marginLeft='1.5em' marginTop='3rem'>
              <ConnectWallet />
            </Box>
          </List>

        </Box>
      </Drawer>
      <Box marginLeft='auto'>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuRoundedIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Box>
    </React.Fragment>
  )
}

export default DrawerComponent;