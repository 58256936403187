import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { handleConnection, handleDisconnection } from '../../redux/actions';
import { SET_PROVIDER_MODAL_OPEN } from '../../redux/action-types';
import { connect } from 'react-redux';

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: { sm: '350px', md: '500px' },
    margin: { sm: 'auto', md: 'auto' }
};

const ConnectWallet = props => {
    const [useDeepLink, setUseDeepLink] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        const { ethereum } = window;
        if (!ethereum) {
            setUseDeepLink(true);
        }
    }, []);

    return (
        <div>
            <Button variant='contained'
                elevation='0'
                className='explorebtns'
                fontWeight='600'
                position='realative'
                onClick={!props.account ? () => props.openProviderModal()
                    : () => props.handleDisconnection()}
            >
                <span className='btnsex'>
                    <AccountBalanceWalletRoundedIcon />
                </span>{props.account ? props.account.slice(0, 5) + "..." + props.account.slice(-5) : "Connect Wallet"}
            </Button>

            <Dialog
                fullScreen={fullScreen}
                open={props.providerModalOpen}
                onClose={props.closeProviderModal}
                aria-labelledby="responsive-dialog-title"

            >
                <Box sx={style}>
                    <DialogTitle id="responsive-dialog-title" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography variant='h5'>
                                Connect Wallet
                            </Typography>
                        </Box>
                        <DialogActions>
                            <Button variant='text'
                                autoFocus
                                onClick={() => props.closeProviderModal()}
                            >
                                <CloseIcon />
                            </Button>
                        </DialogActions>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Box paddingY='1rem'>
                                <Box marginY='1rem'>
                                    <Button variant='contained'
                                        elevation='0'
                                        width='100%'
                                        className='walletsconnect'
                                        fontWeight='600'
                                        position='realative'
                                        disabled={props.isConnecting}
                                        onClick={!useDeepLink ? () => { props.handleConnection('Metamask') }
                                            : () => {
                                                var url = 'https://metamask.app.link/dapp/' + window.location.host + window.location.pathname;
                                                window.open(url, '_blank');
                                            }}
                                    >
                                        <span className='btnsexs'>
                                            <AccountBalanceWalletRoundedIcon />
                                        </span><span style={{ color: props.isConnecting ? '#ddd' : 'white' }}>Metamask</span>
                                    </Button>
                                </Box>
                                <Box marginTop='2rem'>
                                    <Button variant='contained'
                                        elevation='0'
                                        width='100%'
                                        className='walletsconnect'
                                        fontWeight='600'
                                        position='realative'
                                        disabled={props.isConnecting}
                                        onClick={() => props.handleConnection('WalletConnect')}
                                    >
                                        <span className='btnsexs'>
                                            <AccountBalanceWalletRoundedIcon />
                                        </span><span style={{ color: props.isConnecting ? '#ddd' : 'white' }}>WalletConnect</span>
                                    </Button>
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        providerModalOpen: state.providerModalOpen,
        account: state.account,
        isConnecting: state.isConnecting,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleConnection: providerChoice => dispatch(handleConnection(providerChoice)),
        openProviderModal: () => dispatch({ type: SET_PROVIDER_MODAL_OPEN, payload: true }),
        closeProviderModal: () => dispatch({ type: SET_PROVIDER_MODAL_OPEN, payload: false }),
        handleDisconnection: () => dispatch(handleDisconnection()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectWallet);