import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';


const roadmaps = [
    {
        title: 'June 2022',
        subtitle: 'NFT phase 120 NFTs',
    },
    {
        title: 'September 2022 ',
        subtitle: 'Coming Soon',
        bottomtext: '',
    },
    {
        title: 'January 2023',
        subtitle: 'Coming Soon',
        bottomtext: '',
    },
    {
        title: 'March 2023',
        subtitle: 'Coming Soon',
        bottomtext: '',
    },
]


const RoadmapComponents = () => {
    return (
        <div>
            <Box paddingY='4rem' bgcolor='#627B7C'>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item md='12' xs='12'>
                            <Box textAlign='center' marginBottom='4rem'>
                                <Typography
                                    variant='h4'
                                    gutterBottom
                                    component='h4'
                                    fontWeight='600'
                                    color='#fff'
                                >
                                    Roadmap
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box position='relative' className='beforelinesS'>
                        <Grid container spacing={2}>
                            <Grid item lg='12' md='12' sm='6' xs='12'>
                                <Box>
                                    <Typography color='#fff' textAlign='center' variant='h4' fontWeight='400' gutterBottom>
                                        Coming Soon
                                    </Typography>
                                </Box>
                            </Grid>
                            {/* {roadmaps.map((items) => (
                                <Grid item lg='3' md='6' sm='6' xs='12' key={items}>
                                    <Box>
                                        <Box bgcolor='#556B6C' borderRadius='23px' padding='1.5rem 1.8rem' position='relative'>
                                            <Box display='flex'>
                                                <CalendarMonthOutlinedIcon sx={{ color: '#8ECDCC', fontSize: '30px' }} />
                                                <Box marginLeft='7px'>
                                                    <Typography color='#fff' variant='h5' fontWeight='500' gutterBottom>
                                                        {items.title}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography color='#fff' variant='h6' fontWeight='500'>
                                                    {items.subtitle}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box textAlign='center' marginTop='0.5rem'>
                                            <Typography color='#fff' variant='body' fontWeight='400'>
                                            {items.bottomtext}
                                        </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}*/}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default RoadmapComponents;