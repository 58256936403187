import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { throttle } from 'lodash';
import {
    HANDLE_CONNECTION_REQUEST,
    HANDLE_CONNECTION_SUCCESS,
    HANDLE_CONNECTION_FAILURE,
    SET_PROVIDER_MODAL_OPEN,
    RESET,
    LOAD_PUBLIC_CONTRACT,
    SET_PUBLIC_INFO,
    SET_MY_INFO,
    PROVIDER_ACTION_PENDING,
    PROVIDER_ACTION_COMPLETE,
    PROVIDER_ACTION_FAILURE,
} from "./action-types";

const initialState = {
    provider: null,
    contract: null,
    account: null,
    balance: null,
    web3: null,
    isConnecting: false,
    providerModalOpen: false,
    loaders: {
        providerAction: false,
    },
    errors: {
        providerAction: '',
    },
    publicContractWeb3: null,
    selectedAccount: {
        tokenBalance: null
    },
    publicInfo: {
        bnbToUsd: null,
        tokenToUsd: null,
        totalSupply: null,
        blackHoleTokenBal: null,
        autoRebase: null,
        nextRebaseTime: null,
        rebaseRate: null,
        pancakeReserveTokenBal: null,
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_CONNECTION_REQUEST:
            return { ...state, isConnecting: true, }
        case HANDLE_CONNECTION_SUCCESS:
            return { ...state, isConnecting: false, ...action.payload, }
        case HANDLE_CONNECTION_FAILURE:
            return {
                ...state,
                isConnecting: false,
                connectionError: action.payload
            }
        case PROVIDER_ACTION_PENDING:
            return { ...state, loaders: { ...state.loaders, providerAction: true } }
        case PROVIDER_ACTION_COMPLETE:
            return { ...state, loaders: { ...state.loaders, providerAction: false } }
        case PROVIDER_ACTION_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, providerAction: false },
                errors: { ...state.errors, providerAction: action.payload }
            }
        case SET_PROVIDER_MODAL_OPEN:
            return { ...state, providerModalOpen: action.payload, }
        case LOAD_PUBLIC_CONTRACT:
            return { ...state, publicContractWeb3: action.payload }
        case SET_PUBLIC_INFO:
            return { ...state, publicInfo: { ...state.publicInfo, ...action.payload } }
        case SET_MY_INFO:
            return { ...state, selectedAccount: { ...state.selectedAccount, ...action.payload } }
        case RESET:
            return {
                ...state,
                selectedAccount: {
                    tokenBalance: null
                },
                provider: null,
                contract: null,
                account: null,
                balance: null,
                web3: null,
            }
        default:
            return state
    }
}

const store = createStore(reducer, applyMiddleware(thunk));

store.subscribe(throttle(() => {
    console.log('Current state: ', store.getState());
}, 5000));

export default store;