import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react'

import teamsimg from '../../images/team/team-1.jpg'
import teamsimg2 from '../../images/team/team-2.jpg'
import teamsimg3 from '../../images/team/team-3.jpg'
import teamsimg4 from '../../images/team/team-4.jpg'
import teamsimg5 from '../../images/team/team-5.jpg'



const NetworkTeam = () => {
    return (
        <div>
            <Box className='teamsbgimg'>
                <Container maxWidth='xl'>
                    <Grid container spacing={2}>
                        <Grid item md='12' xs='12'>
                            <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap'>
                                <Box className='teamsboxs' >
                                    <Box>
                                        <img
                                            src={teamsimg} width='100%' height='276px' style={{ borderRadius: '5px' }}
                                        />
                                    </Box>
                                    <Box textAlign='center' paddingBottom='10px'>
                                        <Typography variant='h6' fontWeight='400' color='#fff' components='div'>
                                            Albert Wong
                                        </Typography>
                                        <Typography className='teamline' variant='body' fontWeight='400' color='#fff' gutterBottom components='div'>
                                            CEO, HKSTP
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='teamsboxs' >
                                    <Box>
                                        <img
                                            src={teamsimg2} width='100%' height='276px' style={{ borderRadius: '5px' }}
                                        />
                                    </Box>
                                    <Box textAlign='center' paddingBottom='10px'>
                                        <Typography variant='h6' fontWeight='400' color='#fff' components='div'>
                                            Peter Yan
                                        </Typography>
                                        <Typography className='teamline' variant='body' fontWeight='400' color='#fff' gutterBottom components='div'>
                                            CEO, Cyberport
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='teamsboxs' >
                                    <Box>
                                        <img
                                            src={teamsimg5} width='100%' height='276px' style={{ borderRadius: '5px' }}
                                        />
                                    </Box>
                                    <Box textAlign='center' paddingBottom='10px'>
                                        <Typography variant='h6' fontWeight='400' color='#fff' components='div'>
                                            Duncan Chiu
                                        </Typography>
                                        <Typography className='teamline' variant='body' fontWeight='400' color='#fff' gutterBottom components='div'>
                                            Legislative Council Member <br /> Technology & Innovation Constituency
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='teamsboxs' >
                                    <Box>
                                        <img
                                            src={teamsimg3} width='100%' height='276px' style={{ borderRadius: '5px' }}
                                        />
                                    </Box>
                                    <Box textAlign='center' paddingBottom='10px'>
                                        <Typography variant='h6' fontWeight='400' color='#fff' components='div'>
                                            Rosana Wong
                                        </Typography>
                                        <Typography className='teamline' variant='body' fontWeight='400' color='#fff' gutterBottom components='div'>
                                            Founder & President<br /> Ophylla Ventures
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='teamsboxs' >
                                    <Box>
                                        <img
                                            src={teamsimg4} width='100%' height='276px' style={{ borderRadius: '5px' }}
                                        />
                                    </Box>
                                    <Box textAlign='center' paddingBottom='10px'>
                                        <Typography variant='h6' fontWeight='400' color='#fff' components='div'>
                                            Erica Ma
                                        </Typography>
                                        <Typography className='teamline' variant='body' fontWeight='400' color='#fff' gutterBottom components='div'>
                                            Managing Partner<br /> CoCoon lgnite Ventures
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default NetworkTeam;